import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <div key={item.title} className="column is-12">
        <section
        className="section columns"
        style = {{

        }}
        >
          <div className="column is-4"> 
            <h3>{item.title}</h3>
            <div
              style={{
                width: '200px',
                display: 'inline-block',
              }}
            >
              <a href={item.link} target="_blank">
                <PreviewCompatibleImage imageInfo={item} />
              </a>
            </div>
          </div>
          <div
           dangerouslySetInnerHTML={{ __html: item.text }} 
           className="column is-8"
          />
        </section>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
